import React, { useContext } from 'react'
import { Routes as Router, Route, Navigate, Outlet } from 'react-router-dom'
import Login from './pages/login/Login'
import { AuthContext } from './context/AuthContext'
import Home from './pages/home/Home'
import Unauthorized from './pages/unauthorized/Unauthorized'
import NotFoundPage from './pages/NotFound'
import MetricsPage from './pages/metrics/Metrics'
import InterestsPage from './pages/interests/Interests'
import ReportsPage from './pages/reports/Reports'
import UsersPage from './pages/users/Users'
import AdministratorsPage from './pages/administrators/AdministratorsPage'

const PrivateRoute: React.FC = () => {
  const { isLoading, token } = useContext(AuthContext)

  if (isLoading) {
    return <></>
  }

  if (token === undefined) return <Navigate to="/login" replace={true} />

  return <Outlet />
}

const Routes: React.FC = () => {
  return (
    <Router>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/metrics" element={<MetricsPage />} />
        <Route path="/interests" element={<InterestsPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/administrators" element={<AdministratorsPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Router>
  )
}

export default Routes
