import React from 'react'
import Sidebar from '../../components/sidebar'

const Home: React.FC = () => {
  return (
    <>
      <Sidebar />
    </>
  )
}

export default Home
