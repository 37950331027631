import React, { useContext } from 'react'
import { firebaseAuth } from '../../lib/firebase'
import { signOut as logout } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

const Unauthorized: React.FC = () => {
  const { setToken } = useContext(AuthContext)
  const navigate = useNavigate()

  const signOut = async () => {
    try {
      await logout(firebaseAuth)
      setToken(undefined)
      navigate('/login')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-screen h-screen">
      <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
        <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
          <div className="relative">
            <div className="absolute">
              <div className="">
                <h1 className="my-2 text-white font-bold text-2xl">
                  Looks like you&apos;ve found the doorway to the great nothing
                </h1>
                <p className="my-2 text-white">Sorry about that!</p>
                <button
                  className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-transparent text-white hover:bg-white hover:text-black"
                  onClick={() => signOut()}
                >
                  Go back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            className="bg-cover bg-no-repeat"
            width={533}
            height={395}
            src="/unplug.png"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
