import { onAuthStateChanged } from 'firebase/auth'
import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { firebaseAuth } from '../lib/firebase'
import { CustomClaims } from '../typings/firebaseAuth'

type Props = {
  children?: ReactNode
}

type IAuthContext = {
  isLoading: boolean
  token?: string
  setToken: (token: string | undefined) => void
}

const initialValue: IAuthContext = {
  isLoading: true,
  token: undefined,
  setToken: () => {}
}

const AuthContext = createContext<IAuthContext>(initialValue)

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState(initialValue.token)
  const [isLoading, setLoading] = useState(initialValue.isLoading)

  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('token')
    if (token) {
      setToken(token)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      setLoading(true)
      if (!user) {
        setLoading(false)
        setToken(undefined)
        localStorage.removeItem('token')
        return
      }

      const idToken = await user?.getIdTokenResult()
      const claims: CustomClaims = idToken.claims
      if (claims.isAdmin === true) {
        setToken(idToken.token)
        localStorage.setItem('token', idToken.token)
      } else {
        setToken(undefined)
        localStorage.removeItem('token')
      }

      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ isLoading, token, setToken }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
