import React, { useContext } from 'react'
import { firebaseAuth, googleAuthProvider } from '../../lib/firebase'
import { signInWithPopup } from 'firebase/auth'
import { CustomClaims } from '../../typings/firebaseAuth'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

const Login: React.FC = () => {
  const { setToken } = useContext(AuthContext)
  const navigate = useNavigate()

  const signIn = async () => {
    try {
      const userCredential = await signInWithPopup(
        firebaseAuth,
        googleAuthProvider
      )
      const user = await userCredential.user.getIdTokenResult()
      const claims: CustomClaims = user.claims
      if (claims.isAdmin === true) {
        setToken(user.token)
        return navigate('/')
      }

      navigate('/unauthorized')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="h-screen">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img src="/LogoWhite.svg" width={380} height={50} alt="logo" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div>
            <button
              type="button"
              className="flex w-full items-center justify-center rounded-md border-2 border-white bg-transparent px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-white hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              onClick={() => signIn()}
            >
              <svg
                className="w-4 h-4 mr-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" />
              </svg>
              Sign in with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
