import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { AuthProvider } from './context/AuthContext'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
