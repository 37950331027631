import React, { Fragment, useContext, useEffect } from 'react'
import Sidebar from '../../components/sidebar'
import Loader from '../../components/loader'
import InterestCategory from '../../models/interestCategory'
import Interest from '../../models/interest'
import ConfirmationAlert from '../../components/confirmationAlert'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import EditInterestDialog from '../../components/editInterestDialog'
import EditInterestCategoryDialog from '../../components/editInterestCategoryDialog'

const InterestsPage: React.FC = () => {
  const { token } = useContext(AuthContext)
  const [refresh, setRefresh] = React.useState(true)
  const [softRefresh, setSoftRefresh] = React.useState(false)
  const [isLoading, setLoading] = React.useState(true)
  const [interestCategories, setCategories] = React.useState<
    InterestCategory[]
  >([])
  const [draggingCategory, setDraggingCategory] =
    React.useState<InterestCategory | null>(null)
  const [draggingInterest, setDraggingInterest] =
    React.useState<Interest | null>(null)
  const [showDeleteCategory, setShowDeleteCategory] = React.useState(false)
  const [selectedCategory, setSelectedCategory] =
    React.useState<InterestCategory | null>(null)
  const [showDeleteInterest, setShowDeleteInterest] = React.useState(false)
  const [selectedInterest, setSelectedInterest] =
    React.useState<Interest | null>(null)
  const [showEditCategory, setShowEditCategory] = React.useState(false)
  const [showEditInterest, setShowEditInterest] = React.useState(false)

  useEffect(() => {
    const fetchInterests = () => {
      setRefresh(false)
      if (!softRefresh) setLoading(true)
      axios
        .get(process.env.REACT_APP_API_URL + '/interest/list', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => setCategories(response.data.data))
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false)
          setSoftRefresh(false)
        })
    }

    if (refresh) {
      fetchInterests()
    }
  }, [refresh, softRefresh, token])

  const onSubmitInterestCategory = (category: InterestCategory) => {
    if (category.id === undefined) {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/interest/interest-category',
          {
            name: category.name.trim(),
            description: category.description.trim(),
            order: interestCategories.length + 1
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          if (response.status === 201) {
            setSoftRefresh(true)
            setRefresh(true)
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setSelectedCategory(null)
          setShowEditCategory(false)
        })
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/interest/interest-category/edit',
          {
            id: category.id,
            name: category.name.trim(),
            description: category.description.trim()
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSoftRefresh(true)
            setRefresh(true)
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setSelectedCategory(null)
          setShowEditCategory(false)
        })
    }
  }

  const onSubmitInterest = (interest: Interest, categoryId: number) => {
    if (interest.id === undefined) {
      const order =
        (interestCategories.find((category) => category.id === categoryId)
          ?.interests.length ?? 0) + 1
      axios
        .post(
          process.env.REACT_APP_API_URL + '/interest',
          {
            title: interest.title.trim(),
            value: interest.value.trim(),
            order: order,
            categoryId: categoryId
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          if (response.status === 201) {
            setSoftRefresh(true)
            setRefresh(true)
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setSelectedCategory(null)
          setSelectedInterest(null)
          setShowEditInterest(false)
        })
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/interest/edit',
          {
            id: interest.id,
            title: interest.title.trim(),
            value: interest.value.trim()
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSoftRefresh(true)
            setRefresh(true)
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setSelectedCategory(null)
          setSelectedInterest(null)
          setShowEditInterest(false)
        })
    }
  }

  const onDragCategoryStart = (
    event: React.DragEvent<HTMLTableRowElement>,
    item: InterestCategory
  ) => {
    setDraggingCategory(item)
    event.dataTransfer.setData('text/plain', '')
  }

  const onDragInterestStart = (
    event: React.DragEvent<HTMLTableRowElement>,
    item: Interest
  ) => {
    setDraggingInterest(item)
    event.dataTransfer.setData('text/plain', '')
  }

  const onDragCategoryEnd = () => setDraggingCategory(null)
  const onDragInterestEnd = () => setDraggingInterest(null)

  const onDragCategoryOver = (event: React.DragEvent<HTMLTableRowElement>) =>
    event.preventDefault()
  const onDragInterestOver = (event: React.DragEvent<HTMLTableRowElement>) =>
    event.preventDefault()

  const onDropCategory = (
    event: React.DragEvent<HTMLTableRowElement>,
    item: InterestCategory
  ) => {
    if (!draggingCategory) return

    axios
      .post(
        process.env.REACT_APP_API_URL + '/interest/interest-category/swap',
        {
          firstId: draggingCategory.id,
          secondId: item.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setSoftRefresh(true)
          setRefresh(true)
        }
      })
      .catch((error) => console.error(error))
  }

  const onDropInterest = (
    event: React.DragEvent<HTMLTableRowElement>,
    item: Interest
  ) => {
    if (!draggingInterest) return

    axios
      .post(
        process.env.REACT_APP_API_URL + '/interest/swap',
        {
          firstId: draggingInterest.id,
          secondId: item.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setSoftRefresh(true)
          setRefresh(true)
        }
      })
      .catch((error) => console.error(error))
  }

  const deleteCategory = () => {
    if (!selectedCategory) return
    axios
      .delete(process.env.REACT_APP_API_URL + '/interest/interest-category', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          id: selectedCategory.id
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setSoftRefresh(true)
          setRefresh(true)
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setSelectedCategory(null)
        setShowDeleteCategory(false)
      })
  }

  const deleteInterest = () => {
    if (!selectedInterest || !selectedCategory) return
    axios
      .delete(process.env.REACT_APP_API_URL + '/interest/', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          id: selectedInterest.id,
          categoryId: selectedCategory.id
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setSoftRefresh(true)
          setRefresh(true)
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setSelectedCategory(null)
        setSelectedInterest(null)
        setShowDeleteInterest(false)
      })
  }

  return (
    <>
      <Sidebar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="bg-gray-900 py-10">
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-300">
                          Interests
                        </h1>
                        <p className="mt-2 text-sm text-white opacity-50">
                          A list of all the interests and categories.
                        </p>
                        <p className="mt-2 text-sm text-white opacity-50">
                          Hint: Drag and drop to reorder the categories and the
                          interests.
                        </p>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => setShowEditCategory(true)}
                        >
                          Add Category
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 text-gray-300">
                          <table className="min-w-full divide-y divide-gray-700">
                            <tbody className="bg-gray-900">
                              {interestCategories.map((category) => (
                                <Fragment key={category.id}>
                                  <tr
                                    className="border-gray-200"
                                    draggable={true}
                                    onDragStart={(event) =>
                                      onDragCategoryStart(event, category)
                                    }
                                    onDragEnd={onDragCategoryEnd}
                                    onDragOver={onDragCategoryOver}
                                    onDrop={(event) =>
                                      onDropCategory(event, category)
                                    }
                                  >
                                    <th
                                      colSpan={5}
                                      scope="colgroup"
                                      className="bg-gray-800 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 sm:pl-3"
                                    >
                                      <div className="flex items-center">
                                        <div className="grow">
                                          <h3 className="text-gray-300 font-bold">
                                            {category.name}
                                          </h3>
                                          <p className="text-gray-300 font-light">
                                            {category.description}
                                          </p>
                                        </div>
                                        <div>
                                          <button
                                            type="button"
                                            className="mr-4 text-indigo-600 hover:text-indigo-900"
                                            onClick={() => {
                                              setSelectedCategory(category)
                                              setShowEditInterest(true)
                                            }}
                                          >
                                            Add Interest
                                          </button>
                                          <button
                                            className="text-indigo-600 hover:text-indigo-900"
                                            onClick={() => {
                                              setSelectedCategory(category)
                                              setShowEditCategory(true)
                                            }}
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className="text-red-600 hover:text-red-900 ml-2"
                                            onClick={() => {
                                              setSelectedCategory(category)
                                              setShowDeleteCategory(true)
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                  {category.interests
                                    .sort((a, b) => a.order - b.order)
                                    .map((interest) => (
                                      <tr
                                        key={interest.id}
                                        draggable={true}
                                        onDragStart={(event) =>
                                          onDragInterestStart(event, interest)
                                        }
                                        onDragEnd={onDragInterestEnd}
                                        onDragOver={onDragInterestOver}
                                        onDrop={(event) =>
                                          onDropInterest(event, interest)
                                        }
                                      >
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-300 sm:pl-3">
                                          {interest.title}
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                          <button
                                            className="text-indigo-600 hover:text-indigo-900"
                                            onClick={() => {
                                              setSelectedCategory(category)
                                              setSelectedInterest(interest)
                                              setShowEditInterest(true)
                                            }}
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className="text-red-600 hover:text-red-900 ml-2"
                                            onClick={() => {
                                              setSelectedCategory(category)
                                              setSelectedInterest(interest)
                                              setShowDeleteInterest(true)
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showDeleteCategory && selectedCategory && (
          <ConfirmationAlert
            title={`Delete ${selectedCategory.name}`}
            description="Are you sure you want to delete this category? This action cannot be undone."
            actionTitle="Delete"
            isOpen={showDeleteCategory}
            action={() => deleteCategory()}
            onClose={() => {
              setSelectedCategory(null)
              setShowDeleteCategory(false)
            }}
          />
        )}
        {showDeleteInterest && selectedInterest && (
          <ConfirmationAlert
            title={`Delete ${selectedInterest.title}`}
            description="Are you sure you want to delete this interest? This action cannot be undone."
            actionTitle="Delete"
            isOpen={showDeleteInterest}
            action={() => deleteInterest()}
            onClose={() => {
              setSelectedCategory(null)
              setSelectedInterest(null)
              setShowDeleteInterest(false)
            }}
          />
        )}
        {showEditCategory && (
          <EditInterestCategoryDialog
            category={selectedCategory}
            isOpen={showEditCategory}
            onSubmit={onSubmitInterestCategory}
            onClose={() => {
              setSelectedCategory(null)
              setShowEditCategory(false)
            }}
          />
        )}
        {showEditInterest && selectedCategory && (
          <EditInterestDialog
            category={selectedCategory.id}
            interest={selectedInterest}
            isOpen={showEditInterest}
            onSubmit={onSubmitInterest}
            onClose={() => {
              setSelectedCategory(null)
              setSelectedInterest(null)
              setShowEditInterest(false)
            }}
          />
        )}
      </main>
    </>
  )
}

export default InterestsPage
