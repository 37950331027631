import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChartBarIcon,
  CogIcon,
  FlagIcon,
  HomeIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { User, onAuthStateChanged, signOut } from 'firebase/auth'
import { firebaseAuth } from '../lib/firebase'
import { AuthContext } from '../context/AuthContext'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const navigation = (currentRoute: string) => [
  {
    name: 'Dashboard',
    href: '/',
    icon: HomeIcon,
    current: currentRoute === '/'
  },
  {
    name: 'Metrics',
    href: '/metrics',
    icon: ChartBarIcon,
    current: currentRoute === '/metrics'
  },
  {
    name: 'Interests',
    href: '/interests',
    icon: WrenchScrewdriverIcon,
    current: currentRoute === '/interests'
  },
  {
    name: 'Reports',
    href: '/reports',
    icon: FlagIcon,
    current: currentRoute === '/reports'
  },
  {
    name: 'Users',
    href: '/users',
    icon: UserGroupIcon,
    current: currentRoute === '/users'
  }
]
const manageAccount = (currentRoute: string) => [
  {
    id: 1,
    name: 'Administrators',
    icon: CogIcon,
    current: currentRoute === '/administrators'
  },
  {
    id: 2,
    name: 'Sign out',
    icon: ArrowLeftOnRectangleIcon,
    current: false
  }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Sidebar: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { setToken } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  const handleItemAction = (itemId: number) => {
    if (itemId === 1) {
      goToAdministrators()
    } else if (itemId === 2) {
      logout()
    }
  }

  const goToAdministrators = () => {
    navigate('/administrators')
  }

  const logout = async () => {
    await signOut(firebaseAuth)
    setToken(undefined)
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        width={50}
                        height={50}
                        className="h-8 w-auto"
                        src="/LogoIcon.svg"
                        alt="Couplr Admin"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {/* {navigation(pathname).map((item) => (
                              <li key={item.name}>
                                <Link
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))} */}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Manage Account
                          </div>
                          <ul className="-mx-2 mt-2 space-y-1">
                            {/* {manageAccount(pathname).map((item) => (
                              <li key={item.name}>
                                <div
                                  onClick={() => handleItemAction(item.id)}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:cursor-pointer'
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                              </li>
                            ))} */}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                width={50}
                height={50}
                className="h-8 w-auto"
                src="/LogoIcon.svg"
                alt="Couplr Admin"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation(pathname).map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Manage Account
                  </div>
                  <ul className="-mx-2 mt-2 space-y-1">
                    {manageAccount(pathname).map((item) => (
                      <li key={item.name}>
                        <div
                          onClick={() => handleItemAction(item.id)}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:cursor-pointer'
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  {currentUser && (
                    <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white">
                      <img
                        className="h-8 w-8 rounded-full bg-gray-800"
                        width={32}
                        height={32}
                        src={`https://ui-avatars.com/api/?name=${currentUser?.displayName}`}
                        alt="profile"
                      />
                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true">{currentUser?.displayName}</span>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            Dashboard
          </div>
          {currentUser && (
            <div>
              <span className="sr-only">Your profile</span>
              <img
                className="h-8 w-8 rounded-full bg-gray-800"
                width={32}
                height={32}
                src={`https://ui-avatars.com/api/?name=${currentUser?.displayName}`}
                alt="profile"
              />
            </div>
          )}
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{/* Your content */}</div>
        </main>
      </div>
    </>
  )
}

export default Sidebar
