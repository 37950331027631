import React, { useContext, useEffect } from 'react'
import Sidebar from '../../components/sidebar'
import User from '../../models/user'
import Loader from '../../components/loader'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'

const UsersPage: React.FC = () => {
  const { token } = useContext(AuthContext)
  const [isLoading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [totalItems, setTotalItems] = React.useState(0)
  const [users, setUsers] = React.useState<User[]>([])
  const [currentMin, setCurrentMin] = React.useState(0)
  const [currentMax, setCurrentMax] = React.useState(0)

  const pageSize = 20

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          process.env.REACT_APP_API_URL + '/admin/user/list',
          {
            params: {
              page: page,
              pageSize: pageSize
            },
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        const data = response.data.data

        setUsers(data[0])
        setTotalItems(data[1])
        const offset = page === 1 ? 1 : pageSize * (page - 1) + 1
        const max = page === 1 ? 20 : offset + pageSize - 1
        setCurrentMin(offset)
        setCurrentMax(Math.min(max, totalItems))
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchUsers()
  }, [page, totalItems, token])

  const downloadList = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + '/admin/user/generate-list',
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      console.log(response.data.data)

      const users = response.data.data.map(
        (user: { id: string; email: string; profiles: { name: string }[] }) => {
          return {
            id: user.id,
            email: user.email,
            name: `${user.profiles[0].name} & ${user.profiles[1].name}`
          }
        }
      )

      const csv = convertToCSV(users)
      const blob = new Blob([csv], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'users.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error(error)
    }
  }

  const convertToCSV = (data: User[]): string => {
    const header = Object.keys(data[0]).join(',')
    const rows = data.map((item) => Object.values(item).join(','))

    return `${header}\n${rows.join('\n')}`
  }

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page * pageSize + 1 < totalItems) {
      setPage(page + 1)
    }
  }

  return (
    <>
      <Sidebar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="bg-gray-900 py-10">
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-white">
                          Users
                        </h1>
                        <p className="mt-2 text-sm text-gray-300">
                          List of Couplr Users
                        </p>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                          onClick={() => downloadList()}
                        >
                          Download List
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-700">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  ID
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                >
                                  FirebaseID
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                >
                                  Email
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-800">
                              {users.map((user) => (
                                <tr key={user.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {user.id}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                    {user.firebaseId}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                    {user.email}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <nav
                      className="flex items-center justify-between mt-10 border-t border-gray-200 bg-gray-900 px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-white">
                          Showing{' '}
                          <span className="font-medium">{currentMin}</span> to{' '}
                          <span className="font-medium">{currentMax}</span> of{' '}
                          <span className="font-medium">{totalItems}</span>{' '}
                          results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                          onClick={() => previousPage()}
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => nextPage()}
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UsersPage
