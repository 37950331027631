import { getApp, getApps, initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

export const firebaseConfig = {
  apiKey: 'AIzaSyAW2cUx64uXG42NNPET1YF60wl274JrL0w',
  authDomain: 'couplr.firebaseapp.com',
  databaseURL: 'https://couplr.firebaseio.com',
  projectId: 'couplr',
  storageBucket: 'couplr.appspot.com',
  messagingSenderId: '771853815230',
  appId: '1:771853815230:web:1b47cdef7b7633a7573def',
  measurementId: 'G-7VSX3EJ2Y4'
}

export const firebaseApp =
  getApps().length > 0 ? getApp() : initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
export const googleAuthProvider = new GoogleAuthProvider()
