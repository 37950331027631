import React from 'react'
import Sidebar from '../../components/sidebar'

const people = [
  {
    name: 'Pooja',
    title: 'CEO',
    email: 'pooja@couplr.io',
    role: 'Administrator'
  },
  {
    name: 'Sagar',
    title: 'COO',
    email: 'sagar@couplr.io',
    role: 'Administrator'
  },
  {
    name: 'Hani',
    title: 'CMO',
    email: 'hani@couplr.io',
    role: 'Administrator'
  },
  {
    name: 'Humberto',
    title: 'CTO',
    email: 'humberto@couplr.io',
    role: 'Administrator'
  }
]

const AdministratorsPage: React.FC = () => {
  // const [addAdmin, setAddAdmin] = React.useState(false)

  // useEffect(() => {
  // fetch('/api/superadmin/login')
  //   .then((response) => {
  //     setAddAdmin(response.status === 200)
  //   })
  //   .catch((error) => console.error(error))
  // }, [])

  // const addUser = (email: string) => {
  // fetch('/api/superadmin/users', {
  //   method: 'POST',
  //   body: JSON.stringify({ email: email })
  // }).catch((error) => console.error(error))
  // }

  return (
    <>
      <Sidebar />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="bg-gray-900 py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-white">
                        Administrators
                      </h1>
                      <p className="mt-2 text-sm text-gray-300">
                        List of Couplr Administrators
                      </p>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-700">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Role
                              </th>
                              {/* {addAdmin && (
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                >
                                  <span className="sr-only">Invite</span>
                                </th>
                              )} */}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-800">
                            {people.map((person) => (
                              <tr key={person.email}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                  {person.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {person.title}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {person.email}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {person.role}
                                </td>
                                {/* {addAdmin &&
                                  person.email !== 'humberto@couplr.io' && (
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                      <button
                                        className="cursor-pointer text-indigo-400 hover:text-indigo-300"
                                        onClick={() => addUser(person.email)}
                                      >
                                        Add
                                        <span className="sr-only">
                                          , {person.name}
                                        </span>
                                      </button>
                                    </td>
                                  )} */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdministratorsPage
